import React from "react";

export function LogoStyle(color, source, direction) {
  if (source === "desktop") {
    return {
      background: color,
      height: "65px",
      width: "65px",
      padding: "5px",
    };
  } else {
    return {
      background:
        "linear-gradient(to " + direction + ", " + color + ", transparent)",
      height: "50px",
      width: "50px",
      padding: "5px",
    };
  }
}

export function DesktopAwayAbbrScoreStyle(color) {
  return {
    background: color,
    width: "65px",
    color: "white",
    fontWeight: "600",
    fontSize: "larger",
    borderTopLeftRadius: "11px",
  };
}

export function DesktopHomeAbbrScoreStyle(color) {
  return {
    background: color,
    width: "65px",
    color: "white",
    fontWeight: "600",
    fontSize: "larger",
    borderTopRightRadius: "11px",
  };
}

export function DesktopAwayScoreAbbr(props) {
  return <div style={DesktopAwayAbbrScoreStyle(props.color)}>{props.team}</div>;
}

export function DesktopHomeScoreAbbr(props) {
  return <div style={DesktopHomeAbbrScoreStyle(props.color)}>{props.team}</div>;
}

export function DesktopLeftTriangle(color) {
  return {
    width: "35px",
    borderStyle: "solid",
    borderWidth: "93px 0 0 35px",
    borderColor: "transparent transparent transparent " + color,
  };
}

export function DesktopRightTriangle(color) {
  return {
    width: "35px",
    borderStyle: "solid",
    borderWidth: "0 0 93px 35px",
    borderColor: "transparent transparent " + color + " transparent",
  };
}

export function TeamLogo(props) {
  if (props.sports === "NHL") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/" +
          props.team +
          ".svg"
        }
        alt=""
      />
    );
  }
  if (props.sports === "NBA") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/" +
          props.team +
          ".png"
        }
        alt=""
      />
    );
  }
  if (props.sports === "NFL") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/" +
          props.team +
          ".svg"
        }
        alt=""
      />
    );
  }
  if (props.sports === "MLB") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/MLB_" +
          props.team +
          "_logo.png"
        }
        alt=""
      />
    );
  }
  if (props.sports === "WNBA") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/" +
          props.team +
          ".png"
        }
        alt=""
      />
    );
  }
  if (props.sports === "CFB") {
    return (
      <img
        style={LogoStyle(props.color, props.source, props.direction)}
        src={
          "Logos/" +
          props.sports +
          "/" +
          props.team +
          ".png"
        }
        alt=""
      />
    );
  }
  return null;
}

export function colorPicker(abbr, sports) {
  var teamColor = "black";
  if (sports === "NHL") {
    switch (abbr) {
      case "ANA":
        teamColor = "#FC6600";
        break;
      case "ARI":
        teamColor = "#8E0028";
        break;
      case "BOS":
        teamColor = "#FDC423";
        break;
      case "BUF":
        teamColor = "#032E62";
        break;
      case "CAR":
        teamColor = "#E03A3E";
        break;
      case "CBJ":
        teamColor = "#03285C";
        break;
      case "CGY":
        teamColor = "#E03A3E";
        break;
      case "CHI":
        teamColor = "#CE1126";
        break;
      case "COL":
        teamColor = "#6F263D";
        break;
      case "DAL":
        teamColor = "#0D6A4E";
        break;
      case "DET":
        teamColor = "#EC2026";
        break;
      case "EDM":
        teamColor = "#053777";
        break;
      case "FLA":
        teamColor = "#021E41";
        break;
      case "LA":
        teamColor = "#000000";
        break;
      case "MIN":
        teamColor = "#EDAA03";
        break;
      case "MTL":
        teamColor = "#032366";
        break;
      case "NJ":
        teamColor = "#E03A3E";
        break;
      case "NSH":
        teamColor = "#041E42";
        break;
      case "NYI":
        teamColor = "#F99D1C";
        break;
      case "NYR":
        teamColor = "#EE3A43";
        break;
      case "OTT":
        teamColor = "#E4173E";
        break;
      case "PHI":
        teamColor = "#FA6116";
        break;
      case "PIT":
        teamColor = "#FDB71D";
        break;
      case "SJ":
        teamColor = "#09535D";
        break;
      case "STL":
        teamColor = "#FDCA38";
        break;
      case "TB":
        teamColor = "#063E7D";
        break;
      case "TOR":
        teamColor = "#053777";
        break;
      case "VAN":
        teamColor = "#148852";
        break;
      case "VGK":
        teamColor = "#565656";
        break;
      case "WPG":
        teamColor = "#55565A";
        break;
      case "WSH":
        teamColor = "#041E42";
        break;
      default:
        teamColor = "#000000";
        break;
    }
  } else if (sports === "NFL") {
    switch (abbr) {
      case "ARI":
        teamColor = "#B1063A";
        break;
      case "ATL":
        teamColor = "#BD0C17";
        break;
      case "BAL":
        teamColor = "#24135F";
        break;
      case "BUF":
        teamColor = "#DA2127";
        break;
      case "CAR":
        teamColor = "#1685CA";
        break;
      case "CHI":
        teamColor = "#1D1B40";
        break;
      case "CIN":
        teamColor = "#FC2700";
        break;
      case "CLE":
        teamColor = "#E34912";
        break;
      case "DAL":
        teamColor = "#032A5B";
        break;
      case "DEN":
        teamColor = "#E56224";
        break;
      case "DET":
        teamColor = "#106DB0";
        break;
      case "GB":
        teamColor = "#F6BE0C";
        break;
      case "HOU":
        teamColor = "#06192E";
        break;
      case "IND":
        teamColor = "#053B7B";
        break;
      case "JAC":
        teamColor = "#107198";
        break;
      case "KC":
        teamColor = "#C9243F";
        break;
      case "LA":
        teamColor = "#12264B";
        break;
      case "LAC":
        teamColor = "#08214A";
        break;
      case "MIA":
        teamColor = "#168C95";
        break;
      case "MIN":
        teamColor = "#4F2682";
        break;
      case "NE":
        teamColor = "#0D254C";
        break;
      case "NO":
        teamColor = "#000000";
        break;
      case "NYG":
        teamColor = "#04356E";
        break;
      case "NYJ":
        teamColor = "#0C371D";
        break;
      case "LV":
        teamColor = "#000000";
        break;
      case "PHI":
        teamColor = "#074D56";
        break;
      case "PIT":
        teamColor = "#0A5197";
        break;
      case "SEA":
        teamColor = "#76BB41";
        break;
      case "SF":
        teamColor = "#AF1E2B";
        break;
      case "TB":
        teamColor = "#C80F2E";
        break;
      case "TEN":
        teamColor = "#5085BC";
        break;
      case "WAS":
        teamColor = "#782F40";
        break;
      default:
        teamColor = "#000000";
        break;
    }
  } else if (sports === "MLB") {
    switch (abbr) {
      case "AZ":
        teamColor = "#a71930";
        break;
      case "ATL":
        teamColor = "#ce1141";
        break;
      case "BAL":
        teamColor = "#000000";
        break;
      case "BOS":
        teamColor = "#0d2b56";
        break;
      case "CHC":
        teamColor = "#cc3433";
        break;
      case "CIN":
        teamColor = "#c6011f";
        break;
      case "CLE":
        teamColor = "#e31937";
        break;
      case "COL":
        teamColor = "#758690";
        break;
      case "CWS":
        teamColor = "#000000";
        break;
      case "DET":
        teamColor = "#0c2c56";
        break;
      case "HOU":
        teamColor = "#eb6e1f";
        break;
      case "KC":
        teamColor = "#BB9658";
        break;
      case "LAA":
        teamColor = "#ba0021";
        break;
      case "LAD":
        teamColor = "#005a9c";
        break;
      case "MIA":
        teamColor = "#ef2f3c";
        break;
      case "MIL":
        teamColor = "#b6922e";
        break;
      case "MIN":
        teamColor = "#002b5c";
        break;
      case "NYM":
        teamColor = "#F5570F";
        break;
      case "NYY":
        teamColor = "#132448";
        break;
      case "OAK":
        teamColor = "#e9b027";
        break;
      case "PHI":
        teamColor = "#005a9c";
        break;
      case "PIT":
        teamColor = "#fdb827";
        break;
      case "SD":
        teamColor = "#002d62";
        break;
      case "SEA":
        teamColor = "#005c5c";
        break;
      case "SF":
        teamColor = "#fd5a1e";
        break;
      case "STL":
        teamColor = "#15345E";
        break;
      case "TB":
        teamColor = "#092C5C";
        break;
      case "TEX":
        teamColor = "#C40B1C";
        break;
      case "TOR":
        teamColor = "#134a8e";
        break;
      case "WSH":
        teamColor = "#03275D";
        break;
      default:
        teamColor = "#ffffff";
        break;
    }
  } else if (sports === "NBA") {
    switch (abbr) {
      case "PHI":
        teamColor = "#006bb5";
        break;
      case "WAS":
        teamColor = "#0d2240";
        break;
      case "UTA":
        teamColor = "#2b5134";
        break;
      case "TOR":
        teamColor = "#070b0c";
        break;
      case "SAS":
        teamColor = "#000000";
        break;
      case "SAC":
        teamColor = "#5b2b82";
        break;
      case "POR":
        teamColor = "#000000";
        break;
      case "PHX":
        teamColor = "#f9a01b";
        break;
      case "ORL":
        teamColor = "#8E9090";
        break;
      case "OKC":
        teamColor = "#0071ce";
        break;
      case "NYK":
        teamColor = "#ff671b";
        break;
      case "NOP":
        teamColor = "#002b5c";
        break;
      case "MIN":
        teamColor = "#276092";
        break;
      case "MIL":
        teamColor = "#00471a";
        break;
      case "MIA":
        teamColor = "#8b2332";
        break;
      case "MEN":
        teamColor = "#5d76a9";
        break;
      case "LAL":
        teamColor = "#fdb927";
        break;
      case "LAC":
        teamColor = "#EC184C";
        break;
      case "IND":
        teamColor = "#fdbb30";
        break;
      case "HOU":
        teamColor = "#c3002f";
        break;
      case "ATL":
        teamColor = "#e03a3e";
        break;
      case "BKN":
        teamColor = "#000000";
        break;
      case "BOS":
        teamColor = "#008248";
        break;
      case "CHA":
        teamColor = "#26245c";
        break;
      case "CHI":
        teamColor = "#ce1141";
        break;
      case "CLE":
        teamColor = "#6f263d";
        break;
      case "DAL":
        teamColor = "#003da6";
        break;
      case "DEN":
        teamColor = "#0d2240";
        break;
      case "DET":
        teamColor = "#dd0031";
        break;
      case "GSW":
        teamColor = "#0068b3";
        break;
      case "MEM":
        teamColor = "#5A73A4";
        break;
      default:
        teamColor = "#ffffff";
        break;
    }
  } else if (sports === "WNBA") {
    switch (abbr) {
      case "ATL":
        teamColor = "#0c2b5a";
        break;
      case "CHI":
        teamColor = "#ffcd00";
        break;
      case "CON":
        teamColor = "#bf2f38";
        break;
      case "DAL":
        teamColor = "#bed600";
        break;
      case "IND":
        teamColor = "#e03a3e";
        break;
      case "LAS":
        teamColor = "#552583";
        break;
      case "LVA":
        teamColor = "#bf965c";
        break;
      case "MIN":
        teamColor = "#266092";
        break;
      case "NYL":
        teamColor = "#73d2b7";
        break;
      case "PHO":
        teamColor = "#cd5a13";
        break;
      case "SEA":
        teamColor = "#3c5239";
        break;
      case "WAS":
        teamColor = "#737374";
        break;
      default:
        teamColor = "#ffffff";
        break;
    }
  }
  return teamColor;
}
